// ==============================
// * PAGE DESIGNER: CUSTOM SPACE
//
// ? This file gathers all the custom content custom space CSS.
// ==============================


hr {
    &.custom-space {
        border: none;
        background: transparent;
        min-height: 0;
    }
}
